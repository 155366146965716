.modal-backdrop{
  background-color: fade-out($white,0.1);
  opacity: 1 !important;
}

.agegate-modal{

  .modal-dialog{

    .modal-content{
      border: none;
      border-radius: 0;
      background-color: $white;
      text-align: center;
      padding-left: 50px;
      padding-right: 50px;
      box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

      .modal-header{

        padding-top: 40px;
        border: none;

        .modal-logo{
          display: block;
          width: 100%;
          margin: 0;
          padding: 0;

          img{
            display: table;
            margin: 15px auto;
          }

        }

      }

      .modal-body{

        h2{
          font-family: $font-title;
          color: $font-color;
          font-size: 35px;
        }

        p{
          font-family: $font-text;
          color: $font-color;
          font-size: min(max(18px, 5vw), 22px);
          font-style: normal;
          font-weight: 350;
          line-height: min(max(20px, 5vw), 25px);
          letter-spacing: 0.44px;
        }

      }

      .modal-footer{

        border: none;
        padding: 15px 15px 45px 15px;

        .button-container{
          width: 100%;

          .btn-primary{

          outline: none;
          display: inline-block;
          vertical-align: top;
          width: auto;
          position: relative;
          font-weight: 350;
          line-height: 26px;
          letter-spacing: 0.9px;
          text-decoration: none;
          margin: 0 auto;
          padding: 3px 15px;
          background-color: transparent;
          font-family: $font-benton-sans-condensed;
          font-size: 16px;
          font-style: normal;
          text-transform: uppercase;
          color: $font-color;
          border: 2px solid $font-color;
          border-radius: 0;
          transition: all $time-appears-nav ease-in-out;


          &:hover,
          &:focus{
            background-color: $font-color;
            color: $white;
            outline: none !important;
            box-shadow: none !important;
          }

        }

          .btn-primary + .btn-primary{
            border-left: none;
          }
        }

        .mentions-container{
          width: 100%;
          margin-top: 15px;
          font-family: $font-text;
          color: $font-color;
          font-size: 16px;
          font-style: normal;
          font-weight: bold;
          letter-spacing: 0.44px;
        }


      }

    }
  }

}