@import './fonts';


/**
 * COLORS
 */

$white : #FFF;
$black : #000;

$accent-primary : #445622;


$body-color : #FFFFFF;
$footer-color : $accent-primary;

$font-color: $black;

$font-title : "Benton Modern D Cm";
$font-text : "Benton Modern D Cn";
$font-subtitle : "Benton Modern D";
$font-subtitle-italic : "Benton Modern D Italic";
$font-navigation : "Benton Sans Pro Cond";
$font-benton-sans : "Benton Sans Pro";
$font-benton-sans-condensed : "Benton Sans Pro Cond";

$main-font-family : $font-text;

$time-appears-nav : .35s;
$time-appears-social : .35s;
$time-appears-social-icon : .15s;

/*************************/
/* BACK TO TOP - BUTTON */
/*************************/

.progress-wrap {
  position: fixed;
  //right: -50px;
  bottom: 30px;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.2);
  z-index: 998;
  transform: translateY(15px);
  transition: all $time-appears-social ease-in-out;
  background: $white;
  opacity: 1;
  right: 15px;

  svg {

    path {
      fill: none;
    }

    &.progress-circle path {
      stroke: $accent-primary;
      stroke-width: 4;
      box-sizing: border-box;
      -webkit-transition: all 200ms linear;
      transition: all 200ms linear;
    }

  }

  &:after {
    position: absolute;
    font-family: 'unicons';
    content: '\e84b';
    text-align: center;
    line-height: 46px;
    font-size: 24px;
    color: $black;
    left: 0;
    top: 0;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    z-index: 1;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
  }

  &::before {
    position: absolute;
    font-family: 'unicons';
    content: '\e84b';
    text-align: center;
    line-height: 46px;
    font-size: 24px;
    opacity: 0;
    background-image: linear-gradient(298deg, $black, $black);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    left: 0;
    top: 0;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    z-index: 2;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;

  }

  &.is-displayed{
    opacity: 1;
    right: 30px;
  }
}

@media only screen and (min-width: 992px) {

  .progress-wrap {
    right: -50px;
    opacity: 0;

    &.is-displayed{
      opacity: 1;
      right: 30px;
    }
  }

}