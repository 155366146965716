.age-gate-error-page {
  margin: 0;
  height: 100vh;

  .error-page-container{


    .error-page-content{
      padding-top: 80px;
      padding-bottom: 80px;
      text-align: center;

      .image-error{
        max-width: 100%;
        display: block;
        margin: 0 auto 50px auto;
      }

      .text-title{
        > *{
          font-family: $font-title;
          font-size: min(max(45px, 5vw), 65px);
          font-weight: 400;
          line-height: min(max(45px, 5vw), 65px);
          text-transform: uppercase;
          letter-spacing: 0.44px;
          color: $font-color;
        }
      }

      .text-content{
        font-family: $main-font-family;
        font-size: 24px;
        font-style: normal;
        font-weight: 350;
        line-height: 30px;
        letter-spacing: 0.44px;
        color: $font-color;

        > a{
          display: table;
          width: auto;
          position: relative;
          font-weight: 350;
          line-height: 26px;
          letter-spacing: 0.9px;
          color: $accent-primary;
          text-decoration: none;
          margin: 0 auto;

          &:after{
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background-color: $accent-primary;
            position: absolute;
            left: 50%;
            bottom: -2px;
            transform: translateX(-50%);
            opacity: 1;
          }
        }

      }

    }
  }

}