footer {
  margin: 0;


  .inner-footer{
    position: relative;
    background-color: $footer-color;
    padding: 40px 15px;

    .footer-content{
      font-family: $font-benton-sans-condensed;
      font-size: 16px;
      font-style: normal;
      font-weight: 350;
      line-height: 26px;
      letter-spacing: 0.32px;
      color: $white;

      .footer-title{

        margin-bottom: 5px;

        a{
          position: relative;
          font-size: min(max(34px, 5vw), 65px);
          font-weight: 400;
          line-height: min(max(40px, 5vw), 70px);
          text-transform: uppercase;
          font-family: $font-title;
          text-decoration: none;
          color: $white;

          &:after{
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background-color: $white;
            position: absolute;
            left: 50%;
            bottom: 0px;
            transform: translateX(-50%);
            opacity: 1;
          }

          &:focus{
            outline: auto;
          }
        }
      }

      .footer-text{

        margin-top: 15px;

        > a{
          display: inline-block;
          width: auto;
          position: relative;
          font-family: $main-font-family;
          font-size: 15px;
          font-style: normal;
          font-weight: 350;
          line-height: 26px;
          letter-spacing: 0.9px;
          text-transform: uppercase;
          color: $white;
          text-decoration: none;

          &:after{
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background-color: $white;
            position: absolute;
            left: 50%;
            bottom: -2px;
            transform: translateX(-50%);
            opacity: 1;
          }

          &:focus{
            outline: auto;
          }
        }
      }

      .footer-form{

        .form__group {
          position: relative;
          padding: 15px 0 0;
          margin-top: 10px;
          width: 100%;

          .form__field {
            font-family: inherit;
            width: 100%;
            border: 0;
            border-bottom: 1px solid $white;
            outline: 0;
            font-size: 16px;
            letter-spacing: 0.32px;
            color: $white;
            padding: 10px 0;
            background: transparent;
            transition: border-color 0.2s;

            &::placeholder {
              color: transparent;
            }

            &:placeholder-shown ~ .form__label {
              font-size: 16px;
              letter-spacing: 0.32px;
              cursor: text;
              top: 20px;
            }
          }

          .form__label {
            position: absolute;
            top: 0;
            display: block;
            transition: 0.2s;
            font-size: 16px;
            color: $white;
            font-family: $font-benton-sans;
            font-weight: normal;
          }

          .form__field:focus {
            ~ .form__label {
              position: absolute;
              top: 0;
              display: block;
              transition: 0.2s;
              font-size: 14px;
              letter-spacing: 0.32px;
              color: fade-out($white,0.4);
            }
          }

          /* reset input */
          .form__field{
            &:required,&:invalid { box-shadow:none; }
          }

          &.submit{
            padding: 0;
            margin: 0;
            width: 30px;
            height: 30px;
            font-size: 0;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            input[type='submit']{
              -webkit-appearance: none;
              padding: 0;
              margin: 0;
              font-size: 0;
              background-color: transparent;
              border: none;
              outline: none;
              width: 30px;
              height: 30px;
              background-image: url("../../images/icons/icon-submit.svg");
              background-position: center center;
              background-repeat: no-repeat;
              background-size: auto;
            }
          }
        }

      }

    }

    .footer-contact{
      //margin-top: 50px;

      .footer-contact-link{
        > a{
          display: inline-block;
          width: auto;
          position: relative;
          font-family: $main-font-family;
          font-size: 15px;
          font-style: normal;
          font-weight: 350;
          line-height: 26px;
          letter-spacing: 0.9px;
          text-transform: uppercase;
          color: $white;
          text-decoration: none;

          &:after{
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background-color: $white;
            position: absolute;
            left: 50%;
            bottom: -2px;
            transform: translateX(-50%);
            opacity: 1;
          }
        }
      }

      .footer-social{

        margin-top: 15px;

        ul{
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            display: inline-block;
            position: relative;
            width: 50px;
            height: 50px;
            border: 1px solid $white;
            border-radius: 100%;
            margin-right: 10px;

            &:last-of-type {
              margin-right: 0;
            }

            > a {
              position: relative;
              z-index: 2;
              display: block;
              width: 100%;
              height: 100%;
              outline: none;

              &:before{
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate3d(-50%,-50%,0) scale(0);
                opacity: 0;
                width: 50px;
                height: 50px;
                border-radius: 100%;
                background-color: $white;
                transition: all $time-appears-social ease-in-out;
              }

              svg{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate3d(-50%,-50%,0);

                path{
                  transition: all $time-appears-social-icon ease-in-out;
                  transition-delay: 0.2s;
                }
              }

              &:hover,
              &:focus{

                &:before {
                  opacity: 1;
                  transform: translate3d(-50%, -50%, 0) scale(1);
                }

                svg{

                  path{
                    fill: $accent-primary;
                  }

                }

              }

            }

          }
        }

      }
    }

  }

  .post-footer{
    background-color: $white;
    font-family: $font-benton-sans;
    font-size: 13px;
    letter-spacing: 0.42px;
    padding-top: 10px;
    padding-bottom: 10px;

    .footer-copyright {
      margin-top: 15px;
      margin-bottom: 15px;
      flex: 0 0 auto;
      color: $font-color;
      font-weight: 400;
      font-size: 12px;
    }

    .footer-mention{
      margin-top: 30px;
      margin-bottom: 5px;

      p{
        margin: 0;
        color: $font-color;
        font-family: $font-subtitle;
        font-size: 18px;
        letter-spacing: 0.9px;
      }

    }

    .footer-menu{
      margin-top: 15px;
      flex: 0 0 auto;
      .nav-footer{
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          display: inline-block;
          a{
            position: relative;
            display: block;
            color: $font-color;
            text-decoration: none;
            padding: 0 15px;
          }

          &:last-of-type{
            padding-right: 0;
          }
        }
      }
    }
  }

}

@media only screen and (min-width: 992px) {

  footer{

    .inner-footer{

      padding: 80px 15px;

      .footer-contact{
        margin-top: 0;
      }

    }

    .post-footer{

      .footer-copyright{
        margin-top: 0;
        margin-bottom: 0;
        font-size: 14px;
      }

      .footer-menu{
        margin-top: 0;
        .nav-footer{
          li {

            &:last-of-type{
              a{
                padding-right: 0;
              }
            }
          }
        }
      }

    }

  }

}