.header {
  display: block;
  width: 100%;
  position: fixed;
  left: 50%;
  top: 0;
  z-index: 999;
  transform: translateX(-50%);
  padding: 25px 15px;
  background-color: $white;

  .navigation{

    text-align: center;

    > .nav{
      list-style: none;
      margin: 0;
      padding: 0;

      li{
        display: block;
        width: 100%;

        > a{
          position: relative;
          display: block;
          font-family: $font-navigation;
          font-size: 18px;
          font-weight: 400;
          letter-spacing: 0.9px;
          text-transform: uppercase;
          color: $font-color;
          text-decoration: none;
          padding: 15px 50px;

          &:after{
            content: "";
            display: block;
            width: 4px;
            height: 4px;
            border-radius: 100%;
            background-color: $black;
            position: absolute;
            left: 50%;
            bottom: 5px;
            transform: translateX(-50%);
            opacity: 0;
            transition: opacity $time-appears-nav ease;
          }

        }

        &.is-active{

          > a {
            &:after {
              opacity: 1;
            }
          }
        }

      }
    }

    &.navigation-center{
      opacity: 1;
      flex:  0 0 auto;
      //padding: 0 50px;
      padding: 0 100px 0 100px;

      .logo {

        //font-size: 2.5rem;
        //margin-bottom: 0.5rem;

        img{
          max-width: 150px;
          width: 100%;
        }
      }

    }

    &.navigation-lang{
      position: absolute;
      top: 50%;
      right: 15px;
      width: auto;
      transform: translateY(-50%);

      select{
        border-radius: 0 ;
        border: none;
        background: $white;
        font-family: $font-benton-sans-condensed;
        letter-spacing: 0.9px;
        text-transform: uppercase;
        font-size: 15px;
        padding: 5px 10px;

        option{
          padding: 5px;
          color: $font-color;

          &:hover,
          &:focus{
            background-color: $black;
            color: $white;
          }

        }

        &:focus,
        &:focus-visible{
          border: 1px solid $font-color;
          outline: none !important;
        }
      }
    }

  }

  /*&.animated-intro {
    background-color: $white;
    z-index: 999;

    .navigation {
      &.navigation-center {
        opacity: 1;
        flex: 0 0 auto;
        padding: 0 100px 0 100px;
      }
    }

    &.header-condensed {

      .navigation {
        &.navigation-center {
          transition: opacity .25s ease;
          opacity: 1;
        }
      }
    }

  }*/

  &.header-condensed {

    background-color: $white;
    z-index: 999;

    .navigation {

      &.navigation-center {
        transition: opacity .25s ease;
        opacity: 1;
      }
    }
  }

  .header-burger-menu {
    position: absolute;
    z-index: 1001;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);

    .hamburger {
      transition: all 0.3s ease-in-out;
      background-color: transparent;
      border: none;

      .line {
        width: 30px;
        height: 2px;
        background-color: $black;
        display: block;
        margin: 8px 0;
        transition: all 0.3s ease-in-out;
      }

      &.is-active,
      &:focus{

        outline: none;

        .line {
          background-color: $accent-primary;
          margin-left: auto;
          margin-right: auto;
        }

        .line:nth-child(1) {
          width: 15px;
          margin-left: 0;
        }

        .line:nth-child(2) {
          width: 20px;
          margin-left: 0;
        }

        .line:nth-child(3) {
          width: 30px;
          margin-left: 0;
        }

      }

      &:focus{
        outline: auto;
      }

    }


  }

  .header-common{
    position: relative;
    z-index: 999999999;
  }

  .header-mobile{
    position: fixed;
    z-index: 999999998;
    background-color: $white;
    width: 100vw;
    height: 100dvh;
    min-height: 300px;
    left: -100%;
    top: 0;
    transition: all $time-appears-nav ease-in-out;

    &.is-active{
      left: 12px;
    }

    nav{
      height: 100%;
      overflow-y: auto;
      display: block;
      margin-top: 10px;

      > .nav{
        li{
          >a{
            padding: min(max(5px, 2vh), 15px) 50px;
            &:focus{
              outline: auto;
            }
          }
        }
      }

    }
  }

}

@media only screen and (min-width: 992px) {

  .header {

    .navigation{

      > .nav{

        li{
          display: inline-block;
          width: auto;

          > a{
            font-size: 15px;
            padding: 15px 20px;

            &:hover{
              &:after{
                opacity: 1;
              }
            }
          }
        }
      }

      &.navigation-center{
        opacity: 1;
        flex:  0 0 auto;
        padding: 0 30px;

        .logo {
          img{
            max-width: 150px;
          }
        }

      }

      &.navigation-left{
        text-align: right;
      }

      &.navigation-right{
        text-align: left;
      }

      &.navigation-lang{
        right: 30px;
      }

    }

    &.animated-intro {

      .navigation {
        &.navigation-center {
          opacity: 0;
          padding: 0 100px;
        }
      }
    }
  }

}

@media only screen and (min-width: 1200px) {
  .header {

    .navigation{

      > .nav{

        li{

          > a{
            padding: 15px 50px;
          }
        }
      }

      &.navigation-center{
        padding: 0 50px;
      }

    }

  }
}
